import axios from "axios";
import { Message, MessageBox } from "element-ui";
import store from "../store/index";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
function login() {
  window.location.href = "/login";
}
NProgress.configure({
  easing: "ease", // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比
});

var base_url = "https://api.xujianpeng.cn/api/v1";

axios.defaults.timeout = 60000;
axios.defaults.baseURL = base_url;

//http request 拦截器
axios.interceptors.request.use(
  (config) => {
    if (store.state.token) {
      if (config.method === "get") {
        if (config.params) {
          config.params.token = store.state.token;
        } else {
          config.params = {};
          config.params.token = store.state.token;
        }
      }
      if (config.method === "post") {
        if (config.data) {
          config.data.token = store.state.token;
        } else {
          config.data = {};
          config.data.token = store.state.token;
        }
      }
    }
    const qs = require("qs");
    config.data = qs.stringify(config.data);
    // if (store.state.token) {
    //   config.headers["Access-Token"] = store.state.token;
    // }
    // config.headers["Access-Control-Max-Age"] = 86400;
    config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    NProgress.start();
    store.commit("doLoading", true);
    return config;
  },
  (error) => {
    NProgress.remove();
    store.commit("doLoading", false);
    return Promise.reject(error);
  }
);

//http response 拦截器
axios.interceptors.response.use(
  (response) => {
    NProgress.done();
    store.commit("doLoading", false);
    return response;
  },
  (error) => {
    NProgress.remove();
    store.commit("doLoading", false);
    if (error.response) {
      if (error.response.status === 401) {
        // store.commit("deleteToken");
        NProgress.done();
        store.commit("doLoading", false);
        Message.error(error.response.data.message);
        setTimeout(login, 2000);
      } else if (error.response.status === 402) {
        NProgress.done();
        store.commit("doLoading", false);
        MessageBox({
          title: "错误",
          message: error.response.data.message,
          confirmButtonText: "返回",
          callback: () => {
            window.history.go(-1);
          },
        });
      } else if (error.response.status === 403) {
        NProgress.done();
        store.commit("doLoading", false);
        MessageBox({
          title: "错误",
          message: error.response.data.message,
          confirmButtonText: "返回",
          callback: () => {
            window.history.go(-1);
          },
        });
      } else if (error.response.status === 404) {
        NProgress.done();
        store.commit("doLoading", false);
        MessageBox({
          title: "错误",
          message: error.response.data.message,
          confirmButtonText: "返回",
          callback: () => {
            window.history.go(-1);
          },
        });
      } else if (error.response.status === 412) {
        NProgress.done();
        Message.error(error.response.data.message);
        store.commit("showApproval", true);
        return Promise.reject(error);
      } else {
        NProgress.done();
        store.commit("doLoading", false);
        Message.error(error.response.data.message);
      }
    } else {
      NProgress.done();
      store.commit("doLoading", false);
      Message.error("请求失败");
    }
    return Promise.reject(error);
  }
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
