<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <el-button>默认按钮</el-button>
  </div>
</template>

<script>
export default {
  name: "HomeView",
};
</script>
