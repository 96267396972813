export default {
  // 秒转时分秒
  formatSecond(second) {
    var hours = Math.floor(second / 3600);
    var minutes = Math.floor((second % 3600) / 60);
    var remainingSeconds = second % 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (remainingSeconds < 10) {
      remainingSeconds = "0" + remainingSeconds;
    }
    var timeString;
    if (hours > 0) {
      timeString = hours + ":" + minutes + ":" + remainingSeconds;
    } else {
      timeString = minutes + ":" + remainingSeconds;
    }
    return timeString;
  },
  // 将时间转换为秒
  convertToSeconds(time) {
    // 提取小时、分钟和秒
    var timeArr = time.split(":").map(Number);
    var hours, minutes, seconds;
    if (timeArr.length == 3) {
      hours = timeArr[0];
      minutes = timeArr[1];
      seconds = timeArr[2];
    } else {
      hours = 0;
      minutes = timeArr[0];
      seconds = timeArr[1];
    }
    // 计算总秒数
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  },
};
